import dayjs from 'dayjs'

// 加载中文语言包
import 'dayjs/locale/zh-cn'
let localizedFormat = require('dayjs/plugin/localizedFormat')
let customParseFormat = require('dayjs/plugin/customParseFormat')

import relativeTime from 'dayjs/plugin/relativeTime'

// 配置使用处理相对时间的插件
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)
// 配置使用中文语言包
dayjs.locale('zh-cn')

/**
 *  mongoose数据库里的时间格式
	2022-03-19T06:33:56.433+00:00
 * @param {*} time 
 * @returns 
 */
export const timeLongAgo = (time) => {
	return dayjs().to(dayjs(+new Date(time)))
}
/**
 * 
 * @param {*} time 
 * @param {*} fromat 
 * @returns 
 */
export const formatTime = (time, format = 'YYYY-MM-DD HH:mm') => {
	/* console.log(time);
	console.log(format);
	console.log(dayjs(+new Date(time)).format(format));
	console.log(dayjs('2018 三月 15', 'YYYY MMMM DD', 'zh-cn')); */
	return dayjs('2022-03-17T20:10:37.795Z').format(format)
}