import * as api from '../../api/music'

export default {
	namespaced: true,
	state() {
		return {
		}
	},
	actions: {
		getSongList(context, id) {
			return api.getSongList(id)
		},
		async getSongUrl(context, id) {
			const {data} = await api.getSongUrl(id)
			return data.data[0].url
		},
		async getSongLyric(context, id) {
			const {data} = await api.getSongLyric(id)
			return data.lrc.lyric
		}
	}
}