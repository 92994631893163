import * as api from '../../api/root'
import util from '@/utils/util'

export default {
	namespaced: true,
	state: {
		rootInfo: {},
		rootToken: util.sessionStorage.ROOT_TOKEN || false,
	}
	,
	mutations: {
		setRootInfo(state, val) {
			state.rootInfo = val
		},
		setRootToken(state, val) {
			state.rootToken = val
			util.sessionStorage.ROOT_TOKEN = val
		}
	},
	actions: {
		// 初始化root
		rootInit(context, data) {
			return api.api_rootInit(data)
		},
		// 用户登录
		async rootLogin({commit}, data) {
			const res = await api.api_rootLogin(data)
			console.log(res);
			if (res.code) {
				commit('setRootInfo', {
					...res.data, id: res.data._id
				})
				commit('setRootToken', true)
			}
			return res
		},
		handlerDelete(context, data) {
			return api.api_handlerDelete(data)
		},
		handlerSaveArticle(context, data) {
			return api.api_handlerSaveArticle(data)
		},
		getArticleList(context, data) {
			return api.api_getArticleList(data)
		},
		getOneArticle(context, data) {
			return api.api_getOneArticle(data)
		},
		changeArticleInfo(context, data) {
			return api.api_changeArticleInfo(data)
		},
		deleteArticle(context, data) {
			return api.api_deleteArticle(data)
		},
		changeRoot(context, data) {
			return api.api_changeRootPass(data)
		},
		changeArticleMD(context, data) {
			return api.api_changearticleMD(data)
		},
		deleteArticleImg(context, data) {
			return api.api_deleteArticleImg(data)
		},
		// 获取session
		/* 		async getSession({commit}) {
					const data = await api.getSession()
					if (data.code) {
						commit('setUserInfo', {
							...data.data, id: data.data._id
						})
						commit('setLoginStatus', true)
		
					}
					return data
				},*/
		// 退出登录
		rootLoginOut({commit}) {
			commit('setRootInfo', {})
			commit('setRootToken', false)

		}
	}
}