<template>
    <div class="blog-left-side">
        <div class="header">
            <div class="head-image">
                <img src="@/assets/images/QQ图片20210729103459.jpg" alt="" />
            </div>
            <p>来抓我呀 <span class="iconfont icon-xiasanjiaoxing"></span></p>
            <p>认真生活。</p>
        </div>
        <div class="nav_con1 nav_con">
            <h6>导航</h6>
            <ul>
                <li v-for="(item, index) in HOME_MENU_LIST" :key="index" @click="goToUrl(item)">
                    <div class="left-icon">
                        <span class="nav-icon" v-html="item.icon"></span>
                        <a href="javascript:;">{{ item.name }}</a>
                        <!-- <router-link :to="item.url">{{ item.name }}</router-link> -->
                    </div>
                    <span class="select" v-show="index === 0">
                        <svg
                            t="1645626577649"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="1355"
                            width="24"
                            height="24"
                        >
                            <path
                                d="M613.088 536.544l24.128-24.128-193.12-193.12-24.128 24.128 168.992 168.992-168.192 168.192 24.128 24.128 96.416-96.416z"
                                p-id="1356"
                                fill="#777777"
                            ></path>
                        </svg>
                    </span>
                </li>
            </ul>
        </div>
        <div class="nav_con2 nav_con">
            <h6>组成</h6>
            <ul>
                <li v-for="(item, index) in LEFT_SIDE_COMPOSE" :key="index">
                    <div class="left-icon">
                        <span class="nav-icon" v-html="item.icon"> </span>
                        <a href="javascript:;">{{ item.name }}</a>
                    </div>
                    <span class="select" v-show="false">
                        <svg
                            t="1645626577649"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="1355"
                            width="24"
                            height="24"
                        >
                            <path
                                d="M613.088 536.544l24.128-24.128-193.12-193.12-24.128 24.128 168.992 168.992-168.192 168.192 24.128 24.128 96.416-96.416z"
                                p-id="1356"
                                fill="#777777"
                            ></path>
                        </svg>
                    </span>
                </li>
            </ul>
        </div>
        <div class="nav-footer">
            <div class="nav-footer-item cursor" v-for="(item, index) in LEFT_SIDE_ADMIN" :key="index" @click="openNewBlank(item)">
                <p v-html="item.icon"></p>
                <p>{{ item.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {HOME_MENU_LIST, LEFT_SIDE_COMPOSE, LEFT_SIDE_ADMIN} from '@/lib/blog_const'
    export default {
        name: 'BlogLeftSide',
        data() {
            return {
                HOME_MENU_LIST,
                LEFT_SIDE_COMPOSE,
                LEFT_SIDE_ADMIN,
            }
        },
        methods: {
            goToUrl(item) {
                if (item.url.startsWith('http://') || item.url.startsWith('https://')) {
                    return window.open(item.url)
                }
                this.$router.push(item.url)
            },
            openNewBlank(item) {
                let routeDate = this.$router.resolve({
                    path: item.url,
                })
                window.open(routeDate.href, '_blank')
            },
        },
    }
</script>

<style lang="less" scoped>
    .blog-left-side {
        position: relative;
        width: 220px;
        height: calc(100vh - 50px);
        background-color: #f9f9f9;
        .header {
            cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
            height: 170px;
            border-bottom: 1px solid #fff;

            &:hover {
                background: url('~@/assets/images/snow.gif') center / cover;
            }
            .head-image {
                margin: 20px auto 10px;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            p {
                text-align: center;
                &:nth-of-type(1) {
                    color: #777777;

                    font-weight: 700;
                    font-size: 14px;
                    span {
                        font-size: 12px;
                    }
                }
                &:nth-of-type(2) {
                    color: #98a6ad;
                    font-size: 12px;
                }
            }
        }

        .nav_con {
            padding: 15px 0 0 0px;
            h6 {
                color: #98a6ad;
                font-size: 12px;
                font-weight: normal;
                margin-bottom: 10px;
                margin-left: 15px;
            }
            ul li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 40px;
                font-size: 14px;
                padding: 0 20px;
                color: #777777;
                cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
                .left-icon {
                    display: flex;
                    align-items: center;
                    a {
                        color: #777777;
                    }

                    .nav-icon {
                        height: 16px;
                        width: 16px;
                    }
                }
                .select {
                    // width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    color: #777777;
                }
                &:hover {
                    background-color: #ececec;
                }
                a {
                    width: 100%;
                    height: 100%;
                    margin-left: 16px;
                    // font-size: 20px;
                }
            }
        }
        .nav_con1 {
            border-bottom: 1px solid #fff;
        }
        .nav-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50px;
            box-sizing: border-box;
            padding: 5px 0;
            color: #777;
            background-color: #fff;
            font-size: 13px;
            display: flex;
            justify-content: space-around;
            .nav-footer-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p {
                    text-align: center;
                    svg {
                        width: 13px;
                        height: 13px;
                    }
                }
            }
        }
        .cursor {
            cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
        }
    }
</style>
