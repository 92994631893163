import * as api from '../../api/user'
export default {
	namespaced: true,
	state: {
		userInfo: {},
		loginStatus: false,
	}
	,
	mutations: {
		setUserInfo(state, val) {
			state.userInfo = val
		},
		setLoginStatus(state, val) {
			state.loginStatus = val
		}
	},
	actions: {
		// 用户登录
		async userLogin({commit}, data) {
			const userinfo = await api.userLogin(data)
			console.log(userinfo);
			if (userinfo.code) {
				commit('setUserInfo', {
					...userinfo.data, id: userinfo.data._id
				})
				commit('setLoginStatus', true)
			}
			return userinfo
		},
		// 获取session
		async getSession({commit}) {
			const data = await api.getSession()
			if (data.code) {
				commit('setUserInfo', {
					...data.data, id: data.data._id
				})
				commit('setLoginStatus', true)

			}
			return data
		},
		// 退出登录
		async loginOut({commit}) {
			await api.api_loginOut()
			commit('setUserInfo', {})
			commit('setLoginStatus', false)

		}
	}
}