import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/base_const.less'
import '@/assets/base.less'
import '@/assets/font/Source-Sans-Pro/Source-Sans-Pro.css'
import '@/assets/font/Shelf_Life/index.css'
import {message, Pagination, Form, Icon, Input, Checkbox, Button, Menu, Breadcrumb, Dropdown, Select, Upload, Table, Tag, Divider, Switch, Space, Popconfirm, FormModel, Tooltip, notification, Tabs, AutoComplete} from 'ant-design-vue';
import VueAPlayer from 'vue-aplayer'
import {LoadingBar, Poptip} from 'view-design';
import '@/assets/font_3tfa6h2b0w/iconfont.css'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// 若是没有开启Devtools工具，在开发环境中开启，在生产环境中关闭
import request from '@/utils/request'
// Vue.config.devtools = true;
Vue.prototype.$message = message;
Vue.prototype.$Loading = LoadingBar;
Vue.prototype.$axios = request;
Vue.prototype.$notification = notification;
Vue.use(Pagination)
  .use(Form)
  .use(Icon)
  .use(Input)
  .use(Checkbox)
  .use(Button)
  .use(message)
  .use(Menu)
  .use(Breadcrumb)
  .use(Dropdown)
  .use(Select)
  .use(Upload)
  .use(Table)
  .use(Tag)
  .use(Divider)
  .use(Switch)
  .use(Space)
  .use(Popconfirm)
  .use(FormModel)
  .use(LoadingBar)
  .use(mavonEditor)
  .use(Tooltip)
  .use(Tabs)
  .use(AutoComplete)
Vue.component('Poptip', Poptip);
Vue.config.productionTip = false
VueAPlayer.disableVersionBadge = true
router.beforeEach((to, from, next) => {
  LoadingBar.start();
  next();
});

router.afterEach(route => {
  LoadingBar.finish();
});
LoadingBar.config({
  color: '#6F64B6',
  failedColor: '#f0ad4e',
  height: 2
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
