import axios from 'axios'
// import store from '@/store'
// import router from '@/router'

const request = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	timeout: 10000
})


// 添加请求拦截器
request.interceptors.request.use(function (config) {
	// 进行请求配置的修改
	// 如果本地又token就在头部携带
	// 1. 获取用户信息对象
	// const {profile} = store.state.user
	// if (profile.token) {
	// 	config.headers.Authorization = `Bearer ${profile.token}`
	// }
	return config
}, function (error) {
	// 对请求错误做些什么
	return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
	// 对响应数据做点什么
	return response.data
}, function (err) {
	// 对响应错误做点什么
	// 401 状态码，进入该函数
	// 1. 清空无效用户信息
	// 2. 跳转到登录页
	// 3. 跳转需要传参（当前路由地址）给登录页码
	// if (err.response && err.response.status === 401) {
	// 	store.commit('user/setUser', {})
	// 	// 当前路由地址
	// 	// 组件里头：`/user?a=10` $route.path === /user  $route.fullPath === /user?a=10
	// 	// js模块中：router.currentRoute.value.fullPath 就是当前路由地址，router.currentRoute 是ref响应式数据
	// 	const fullPath = encodeURIComponent(router.currentRoute.value.fullPath)
	// 	router.push('/login?redirectUrl=' + fullPath)
	// }

	return Promise.reject(err)
})
export default request
