import request from '../utils/request'

/**
获取所有友链
 */
export const api_getFriendLinks = (data) => {
	return request({
		url: '/friend/getlinks',
		method: 'get',
		params: data
	})
}
/**
 * 是否推荐友链
 * @param {*} data 
 * @returns 
 */
export const api_chaneLinkShow = (data) => {
	return request({
		url: '/friend/sethotlists',
		method: 'post',
		data: data
	})
}
/**
申请友链
 */
export const api_applyFriendLinks = (data) => {
	return request({
		url: '/friend/userLink',
		method: 'post',
		data: data
	})
}

/**
 * 发表留言
 * @param {*} data 
 * @returns 
 */
export const api_setLeaveMessage = (data) => {
	return request({
		url: '/friend/setmessage',
		method: 'post',
		data: data
	})
}
/**
获取留言
*/
export const api_getLeaveMessage = (data) => {
	return request({
		url: '/friend/getmessage',
		method: 'post',
		data: data
	})
}