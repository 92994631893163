import request from '../utils/request'

/**
发表评论
 */
export const api_postComments = (data) => {
	return request({
		url: '/comment/postComments',
		method: 'post',
		data
	})
}
/**
	获取评论
 */
export const api_getComments = (data) => {
	return request({
		url: '/comment/getComments',
		method: 'get',
		params: data
	})
}

// 点赞文章
export const api_likeArticle = (data) => {
	return request({
		url: '/article/likeArticle',
		method: 'get',
		params: data
	})
}

// 浏览量+1
export const api_lookArticle = (data) => {
	// console.log('aaaaa', data);
	return request({
		url: '/article/lookArticle',
		method: 'get',
		params: data
	})
}

// 获取置顶文章
export const api_getTopArticle = () => {
	// console.log('aaaaa', data);
	return request({
		url: '/root/getTopArticle',
		method: 'get',
	})
}

// 获取热门文章
export const api_getHotArticle = () => {
	// console.log('aaaaa', data);
	return request({
		url: '/article/getHotArticle',
		method: 'get',
	})
}