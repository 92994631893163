export default [
	{
		path: 'home',
		name: 'Home',
		component: () => import('@/views/Home'),
	},
	{
		path: 'Article',
		name: 'Article',
		component: () => import('@/views/Article'),
	},
	{
		path: 'links',
		name: 'links',
		component: () => import('@/views/BlogFriend'),
	},
	{
		path: 'LeaveMessage',
		name: 'LeaveMessage',
		component: () => import('@/views/BlogLeaveMessage'),
	},
	{
		path: 'PersonalCenter',
		name: 'PersonalCenter',
		component: () => import('@/views/BlogPersonalCenter'),
	},
	{
		path: 'about-me',
		name: 'AboutMe',
		component: () => import('@/views/BlogAboutMe'),
	}
]