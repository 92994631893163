export default [
	{
		path: 'home',
		name: 'rootHome',
		component: () => import('@/views/root/RootHome'),
		meta: {title: 'Home', icon: 'bank', desc: 'Home', show: true}
	},
	{
		path: 'article-manage',
		name: 'article-manage',
		component: () => import('@/views/root/ArticleManager'),
		meta: {title: 'article-manage', icon: 'folder', desc: '文章管理', show: true}
	},
	{
		path: 'publish-article',
		name: 'publish-article',
		component: () => import('@/views/root/PublishArticle'),
		meta: {title: 'publish-article', icon: 'plus-square', desc: '发布文章', show: true}
	},
	{
		path: 'change-password',
		name: 'change-password',
		component: () => import('@/views/root/ChangeRootPassword'),
		meta: {title: 'change-password', icon: 'edit', desc: '修改密码', show: true}
	},
	{
		path: 'friend-chain',
		name: 'friend-chain',
		component: () => import('@/views/root/FriendChain'),
		meta: {title: 'friend-chain', icon: 'team', desc: '友链管理', show: true}
	},
	{
		path: 'update-article',
		name: 'update-article',
		component: () => import('@/views/root/UpdateArticle'),
		meta: {title: 'friend-chain', icon: 'team', desc: '友链管理', show: false}
	}
]