import * as api from '../../api/friend'

export default {
	namespaced: true,
	state() {
		return {
		}
	},
	actions: {
		getFriendLinks(context, data) {
			return api.api_getFriendLinks(data)
		},
		chaneLinkShow(context, data) {
			return api.api_chaneLinkShow(data)
		},
		applyFriendLinks(context, data) {
			return api.api_applyFriendLinks(data)
		},
		setLeaveMessage(context, data) {
			return api.api_setLeaveMessage(data)
		},
		getLeaveMessage(context, data) {
			return api.api_getLeaveMessage(data)
		},
	}
}