import Vue from 'vue'
import Vuex from 'vuex'
import music from './modules/music'
import user from './modules/user'
import root from './modules/root'
import friend from './modules/friend'
import article from './modules/article'
import settings from './modules/settings'
import getters from './getters'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    music,
    user,
    root,
    settings,
    friend,
    article
  },
  getters
})
