<template>
    <div class="blog-head">
        <div class="header-left">
            <div class="logo" @click="$router.push({name: 'Home'})">
                <img src="@/assets/images/logo.svg" alt="" />
            </div>
            <!-- 统计按钮 -->
            <div class="tong-ji mouse-hover">
                <span class="iconfont icon-tongji"></span>
                <span class="cart"></span>
            </div>
            <!-- 搜索框 -->
            <div class="search">
                <input type="text" placeholder="输入关键词搜索…" name="" id="" />
                <span class="iconfont icon-sousuo"></span>
            </div>
        </div>
        <div class="header-right">
            <!-- 播放器 -->
            <div class="play">
                <aplayer
                    ref="palyer"
                    @play="eventPlay"
                    @pause="eventPause"
                    :autoplay="true"
                    :music="currentMusic"
                    :list="musicList"
                    :listFolded="true"
                    :showLrc="showLrc"
                    :volume="0.4"
                    theme="pic"
                />
                <div class="play-control">
                    <!-- 上一首 -->
                    <span @click="prevMusic" class="aplayer-icon aplayer-icon-back">
                        <svg
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            stroke="currentColor"
                            stroke-width="2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="css-i6dzq1"
                        >
                            <polygon points="11 19 2 12 11 5 11 19"></polygon>
                            <polygon points="22 19 13 12 22 5 22 19"></polygon>
                        </svg>
                    </span>
                    <!-- 播放暂停 -->
                    <span class="aplayer-icon aplayer-icon-play" v-show="!isPlayIng" @click="togglePlay">
                        <svg
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            stroke="currentColor"
                            stroke-width="2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="css-i6dzq1"
                        >
                            <polygon points="5 3 19 12 5 21 5 3"></polygon>
                        </svg>
                    </span>
                    <span class="aplayer-icon aplayer-icon-play" @click="togglePlay" v-show="isPlayIng"
                        ><svg
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            stroke="currentColor"
                            stroke-width="2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="css-i6dzq1"
                        >
                            <rect x="6" y="4" width="4" height="16"></rect>
                            <rect x="14" y="4" width="4" height="16"></rect></svg
                    ></span>
                    <!-- 下一首 -->
                    <span class="aplayer-icon aplayer-icon-forward" @click="nextMusic">
                        <svg
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            stroke="currentColor"
                            stroke-width="2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="css-i6dzq1"
                        >
                            <polygon points="11 19 2 12 11 5 11 19"></polygon>
                            <polygon points="22 19 13 12 22 5 22 19"></polygon>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="head-icon yuan mouse-hover" @click="toggleShowLrc">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16px" height="16px" viewBox="0 0 32 32">
                    <path
                        d="M26.667 5.333h-21.333c-0 0-0.001 0-0.001 0-1.472 0-2.666 1.194-2.666 2.666 0 0 0 0.001 0 0.001v-0 16c0 0 0 0.001 0 0.001 0 1.472 1.194 2.666 2.666 2.666 0 0 0.001 0 0.001 0h21.333c0 0 0.001 0 0.001 0 1.472 0 2.666-1.194 2.666-2.666 0-0 0-0.001 0-0.001v0-16c0-0 0-0.001 0-0.001 0-1.472-1.194-2.666-2.666-2.666-0 0-0.001 0-0.001 0h0zM5.333 16h5.333v2.667h-5.333v-2.667zM18.667 24h-13.333v-2.667h13.333v2.667zM26.667 24h-5.333v-2.667h5.333v2.667zM26.667 18.667h-13.333v-2.667h13.333v2.667z"
                    ></path>
                </svg>
            </div>
            <div class="head-icon message mouse-hover">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-twitch"
                >
                    <path d="M21 2H3v16h5v4l4-4h5l4-4V2zm-10 9V7m5 4V7"></path>
                </svg>
            </div>
            <div class="login mouse-hover" @click="changeLoginDialog">
                <span class="iconfont icon-keyyuechi"></span>
                <span class="cart"></span>
            </div>
        </div>
        <div class="login-dialog" :class="{'show-login-dialog': showLogin}">
            <LoginDialog @changeLoginDialog="changeLoginDialog" />
        </div>
    </div>
</template>

<script>
    import Aplayer from 'vue-aplayer'
    import {musicList} from '@/lib/music_list'
    import {mapActions} from 'vuex'
    import LoginDialog from './LoginDialog.vue'
    export default {
        name: 'BlogHead',
        components: {
            Aplayer,
            LoginDialog,
        },

        data() {
            return {
                musicList,
                currentMusicIndex: 0,
                isPlayIng: false,
                showLrc: true,
                showLogin: false,
            }
        },
        computed: {
            currentMusic() {
                return musicList[this.currentMusicIndex]
            },
        },

        methods: {
            ...mapActions({
                vxGetSongList: 'music/getSongList',
                vxGetSongUrl: 'music/getSongUrl',
                vxGetSongLyric: 'music/getSongLyric',
            }),
            async getSongList(id) {
                console.log('歌曲列表')
                const data = await this.vxGetSongList(id)
                console.log(data)
                let songListTemp = data.playlist.tracks.slice(0, 15)

                console.log(songListTemp)
                this.handlerMusicData(songListTemp)
            },
            async getMusicAndLrc(id, index) {
                let src = await this.vxGetSongUrl(id)
                let lrc = await this.vxGetSongLyric(id)
                this.musicList[index].src = src
                this.musicList[index].lrc = lrc
            },
            handlerMusicData(songList) {
                this.musicList = songList.map((item, index) => {
                    this.getMusicAndLrc(item.id, index)
                    return {
                        src: '',
                        title: item.name,
                        artist: item.ar[0].name, // 演唱者
                        pic: item.al.picUrl,
                        lrc: '',
                    }
                })
            },
            eventPlay() {
                this.isPlayIng = true
            },
            eventPause() {
                this.isPlayIng = false
            },
            prevMusic() {
                if (this.currentMusicIndex <= 0) {
                    this.currentMusicIndex = this.musicList.length - 1
                } else {
                    this.currentMusicIndex--
                }
                this.$refs.palyer.thenPlay()
            },
            nextMusic() {
                this.currentMusicIndex = (this.currentMusicIndex + 1) % this.musicList.length
                console.log(this.$refs.palyer)
                this.$refs.palyer.thenPlay()
            },
            togglePlay() {
                if (this.isPlayIng) {
                    console.log('关闭')
                    this.$refs.palyer.pause()
                } else {
                    this.$refs.palyer.thenPlay()
                }
            },
            toggleShowLrc() {
                this.showLrc = !this.showLrc
            },
            changeLoginDialog() {
                this.showLogin = !this.showLogin
            },
        },
        mounted() {
            // this.getSongList()
            // setTimeout(() => {
            //     console.log(this.musicList[0]);
            // }, 3000);
            // console.log(this.$refs.palyer);
        },
    }
</script>

<style lang="less" scoped>
    .blog-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: @max-width;
        min-width: @min-width;
        position: relative;
        height: 50px;
        background-color: #f9f9f9;
        // box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
        .header-left,
        .header-right {
            display: flex;
            align-items: center;
            background-color: #f9f9f9;
            z-index: 999;
        }
        .mouse-hover {
            cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }
        .cart {
            display: inline-block;
            margin-left: 4px;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-top-color: #777777;
        }
        .logo {
            position: relative;
            width: 220px;
            height: 50px;

            img {
                position: absolute;
                top: -5px;
                left: 20px;
                width: 130px;
                height: 50px;
                &:hover {
                    cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
                }
            }
        }
        .tong-ji {
            width: 58px;
            line-height: 50px;
            text-align: center;
            color: #777777;
            cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
        }
        .search {
            display: flex;
            justify-content: space-between;
            margin-left: 15px;
            width: 185px;
            height: 30px;
            line-height: 30px;
            font-size: 12px;
            border-radius: 30px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
            background-color: #ffffff;
            color: #a7a7a7;
            & > input {
                border: none;
                outline: #ee695d;
                padding-left: 1em;
                font-size: 12px;
                width: 148px;
                height: 30px;
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
                &:focus {
                    width: 148px;
                    height: 30px;
                    box-shadow: 0 0 0 0.1rem rgba(255, 181, 182, 0.7);
                }
            }
            .icon-sousuo {
                display: block;
                text-align: center;
                width: calc(185px - 30px);
                color: #000;
                font-size: 14px;
                font-weight: bold;
            }
        }
        .head-icon {
            color: #5c5c5c;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
        }
        .yuan {
            width: 46px;
            line-height: 50px;
            text-align: center;
            opacity: 0.7;
            a {
                width: 15px;
                height: 15px;
            }
        }
        .message {
            width: 60.84px;
            line-height: 50px;
            text-align: center;
        }
        .login {
            width: 58.84px;
            line-height: 50px;
            text-align: center;
            color: #5c5c5c;
        }
        .play {
            // width: 232px;
            // height: 50px;
            position: relative;
            /deep/ .aplayer {
                background-color: #f9f9f9;
                box-shadow: none;
                overflow: visible !important;
                margin: 0;
                .aplayer-list {
                    background-color: #fff;
                }
                .aplayer-body {
                    height: 50px;
                    .aplayer-pic {
                        margin-top: 5px;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        .aplayer-button {
                            display: none;
                        }
                    }
                    .aplayer-info {
                        height: 50px;
                        width: 165px;
                        padding-top: 5px;
                        overflow: visible !important;
                        .aplayer-lrc {
                            width: 200px;
                        }
                        .aplayer-controller {
                            position: relative;
                            justify-content: flex-end;
                            height: 24px;
                            .aplayer-bar-wrap {
                                position: absolute;
                                bottom: -7px;
                                left: 0;
                                width: 147px;
                                height: 4px;
                                // align-self: end;
                                // padding-bottom: 2px;
                                .aplayer-thumb {
                                    transform: scale(0);
                                    transition: all 0.3s ease-in-out;
                                }
                                &:hover .aplayer-thumb {
                                    transform: scale(1);
                                }
                            }
                            .aplayer-time {
                                .aplayer-time-inner {
                                    display: none;
                                }
                                > button:nth-of-type(2),
                                > button:nth-of-type(1) {
                                    display: none;
                                }
                                .aplayer-volume-wrap .aplayer-volume-bar-wrap {
                                    display: block;
                                }
                                .aplayer-volume-wrap .aplayer-volume-bar-wrap:after {
                                    background-color: transparent;
                                    box-shadow: none;
                                }

                                .aplayer-volume-wrap .aplayer-volume-bar-wrap .aplayer-volume-bar {
                                    height: 0;
                                    transition: all 0.2s ease-in-out;
                                }
                                .aplayer-volume-wrap:hover .aplayer-volume-bar-wrap .aplayer-volume-bar {
                                    height: 27px;
                                }
                                .aplayer-icon-menu {
                                    opacity: 0.8;
                                }
                            }
                        }
                        .aplayer-lrc {
                            position: fixed;
                            // bottom: 0;
                            // left: -250px;
                            // top: 10px;
                            left: -22em;
                            top: 10px;
                            background-color: transparent;
                            &::after {
                                display: none;
                            }
                            &::before {
                                display: none;
                            }
                            .aplayer-lrc-current {
                                color: red;
                            }
                        }
                        // .aplayer-controller .aplayer-time .aplayer-volume-wrap + .aplayer-icon {
                        //     display: none;
                        // }
                        .aplayer-music {
                            padding-bottom: 0;
                            color: #777;
                        }
                    }
                }
                .aplayer-list {
                    position: absolute;
                    width: 100%;
                }
            }
            .play-control {
                position: absolute;
                display: flex;
                justify-content: space-between;
                top: 33px;
                left: 57px;
                width: 70px;
                .aplayer-icon {
                    height: 15px;
                    width: 15px;
                    color: #5c5c5c;
                    &.aplayer-icon-forward {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        /deep/ .login-dialog {
            position: absolute;
            z-index: 800;
            top: -230px;
            right: 0;
            width: 280px;
            min-height: 210px;
            padding: 15px;
            padding-bottom: 6px;
            background-color: #f9f9f9;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
            transition: all 0.3s;
            &.show-login-dialog {
                top: 52px;
            }
            #components-form-demo-normal-login .login-form {
                max-width: 300px;
            }
            #components-form-demo-normal-login .login-form-forgot {
                float: right;
            }
            #components-form-demo-normal-login .login-form-button {
                width: 100%;
            }
            .ant-form-item {
                margin-bottom: 15px;
            }
            .ant-form-item-control {
                line-height: 32px;
            }
            .ant-btn-primary {
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
                background: #448bff linear-gradient(45deg, #448bff, #44e9ff);
            }
            .login-form-button {
                margin: 5px auto;
            }
            .login-form .ant-form-item {
                &:nth-of-type(3) {
                    margin-bottom: 0;
                }
            }
            a {
                color: #1890ff;
                &:hover {
                    color: #40a9ff;
                }
            }
        }
    }
</style>
