import request from '../utils/request'
/**
 * 用户登录，账户不存在就注册
 * @param {*} data 登录表单
 * @returns 
 */
export const userLogin = (data) => {
	return request({
		url: '/user/userlogin',
		method: 'post',
		data
	})
}
/**
 * 获取session
 * @returns session
 */
export const getSession = () => {
	return request({
		url: '/user/getcookie',
		method: 'get',
	})
}
/**
	退出登录
 */
export const api_loginOut = () => {
	return request({
		url: '/user/destory',
		method: 'post',
	})
}