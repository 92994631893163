import * as api from '../../api/article'

export default {
	namespaced: true,
	state() {
		return {
		}
	},
	actions: {
		// 获取评论
		getComments(context, data) {
			// console.log(data);
			return api.api_getComments(data)
		},
		// 发表评论
		postComments(context, data) {
			return api.api_postComments(data)
		},
		likeArticle(context, data) {
			return api.api_likeArticle(data)
		},
		lookArticle(context, data) {
			return api.api_lookArticle(data)
		},
		getTopArticle(context,) {
			return api.api_getTopArticle()
		},
		getHotArticle(context,) {
			return api.api_getHotArticle()
		},
	}
}