import request from '../utils/request'

// http://music.zzhitong.com//toplist 这是获取所有排行榜，我只想要热歌榜，id为3778678,
/**
 * 获取热歌榜所有歌曲信息（有歌曲名，歌手，头像等信息）
 * @returns 
 */
export const getSongList = (id = "3778678") => {
	return request({
		url: 'http://music.zzhitong.com/playlist/detail',
		method: 'GET',
		params: {id: id}
	})
}
/**
 * 获取歌曲url
 * @param {*} id 
 * @returns 
 */
export const getSongUrl = (id) => {
	return request({
		url: 'http://music.zzhitong.com/song/url',
		method: 'GET',
		params: {id: id}
	})

}
/**
 * 获取歌曲歌词
 * @param {*} id 
 * @returns 
 */
export const getSongLyric = (id) => {
	return request({
		url: 'http://music.zzhitong.com/lyric',
		method: 'GET',
		params: {id: id}
	})
}