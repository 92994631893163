import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/HomeLayout'
import adminRouter from './adminRouter'
import blogRouter from './blogRouter'

import store from '@/store'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    redirect: '/home',
    component: Home,
    children: blogRouter
  },
  {
    path: '/root-login',
    name: 'root-login',
    component: () => import('@/views/RootLogin')
  },
  {
    path: '/root',
    redirect: '/root/home',
    component: () => import('@/RootLayout/index.vue'),
    children: adminRouter
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path.split('/').includes('root')) {
    if (store.state.root.rootToken) {
      next()
    } else {
      next('/root-login')
    }
  }
  next()
})


export default router
