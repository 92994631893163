import request from '../utils/request'
// 初始化root账户
export const api_rootInit = () => {
	return request({
		url: '/root',
		method: 'get',
	})
}
/**
 * root用户登录
 * @param {*} data 登录表单
 * @returns 
 */
export const api_rootLogin = (data) => {
	return request({
		url: '/root/rootlogin',
		method: 'post',
		data
	})
}
/**
 * 删除上传的md
 * @param {*} data 
 * @returns 
 */
export const api_handlerDelete = (data) => {
	return request({
		url: '/root/deleteMd',
		method: 'post',
		data
	})
}
/**
 * 保存文章到数据库
 * @param {*} data 
 * @returns 
 */
export const api_handlerSaveArticle = (data) => {
	return request({
		url: '/root/handlermdInfo',
		method: 'post',
		data
	})
}
/**
 * 分页获取文章数据
 * @param {*} data {页码，数量}
 * @returns 
 */
export const api_getArticleList = (data) => {
	return request({
		url: '/root/getall',
		method: 'get',
		params: data

	})
}
/**
 * 获取某一篇文章信息
 * @param {*} data 
 * @returns 
 */
export const api_getOneArticle = (data) => {
	return request({
		url: '/root/getOneArticle',
		method: 'get',
		params: data
	})
}
/**
 * 修改标题 描述以及其他信息，不包括文档和封面图
 * @param {*} data 
 * @returns 
 */
export const api_changeArticleInfo = (data) => {
	return request({
		url: '/root/changeinfo',
		method: 'post',
		data
	})
}
/**
 * 删除文章
 * @param {*} data 
 * @returns 
 */
export const api_deleteArticle = (data) => {
	return request({
		url: '/root/deletearticle',
		method: 'post',
		data
	})
}
/**
 * 修改后台密码
 * @param {*} data 
 * @returns 
 */
export const api_changeRootPass = (data) => {
	return request({
		url: '/root/changeroot',
		method: 'post',
		data
	})
}
/**
 * 修改文章，这里后面要改，不用这个api
 * @param {*} data 
 * @returns 
 */
export const api_changearticleMD = (data) => {
	return request({
		url: '/root/changearticleMD',
		method: 'post',
		data
	})
}
/**
删除md中的图片
 */
export const api_deleteArticleImg = (data) => {
	return request({
		url: '/root/deleteArticleImg',
		method: 'post',
		data
	})
}