<template>
    <div class="blog-right-side">
        <ul class="side-head">
            <li class="select">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-thumbs-up"
                >
                    <path
                        d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"
                    ></path>
                </svg>
            </li>
            <li>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-message-square"
                >
                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                </svg>
            </li>
            <li>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-gift"
                >
                    <polyline points="20 12 20 22 4 22 4 12"></polyline>
                    <rect x="2" y="7" width="20" height="5"></rect>
                    <line x1="12" y1="22" x2="12" y2="7"></line>
                    <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                    <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
                </svg>
            </li>
        </ul>
        <div class="hot-art">
            <h5>热门文章</h5>
            <ul>
                <li v-for="(item, index) in hotArticleList" :key="item._id" @click="$router.push({name: 'Article', query: {articleId: item._id}})">
                    <div class="imgs">
                        <img :src="HOT_ARTICLE_ICON[index]" alt="" />
                    </div>
                    <div class="hot-article-info">
                        <h6>{{ item.articleTitle }}</h6>
                        <p>
                            <span class="right-small-icons"
                                ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16px"
                                    height="16px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-message-circle"
                                    style="position: relative; top: 4px"
                                >
                                    <path
                                        d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
                                    ></path></svg></span
                            >{{ item.viewNumber }}
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="blog-info">
            <h5>博客信息</h5>
            <ul>
                <li>
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-award"
                        >
                            <circle cx="12" cy="8" r="7"></circle>
                            <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg
                        >文章数目
                    </div>
                    <span>246</span>
                </li>
                <li>
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-message-circle"
                        >
                            <path
                                d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
                            ></path></svg
                        >评论数目
                    </div>
                    <span>7646</span>
                </li>
                <li>
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-calendar"
                        >
                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                            <line x1="16" y1="2" x2="16" y2="6"></line>
                            <line x1="8" y1="2" x2="8" y2="6"></line>
                            <line x1="3" y1="10" x2="21" y2="10"></line></svg
                        >运行天数
                    </div>
                    <span>{{ formatTime() }}</span>
                </li>
                <li>
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-activity"
                        >
                            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg
                        >最后活动
                    </div>
                    <span>一小时前</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {HOT_ARTICLE_ICON} from '@/lib/blog_const'
    import {mapActions} from 'vuex'
    // import {formatTime} from '@/utils/dayjs'

    export default {
        name: 'BlogRightSide',
        data() {
            return {
                HOT_ARTICLE_ICON,
                hotArticleList: [],
            }
        },
        methods: {
            ...mapActions({vxGetHotArticle: 'article/getHotArticle'}),
            async getHotArticle() {
                const data = await this.vxGetHotArticle()
                this.hotArticleList = data
            },
            formatTime() {
                let startTime = 1647697485832
                let nowTime = Date.now()
                let time = nowTime - startTime
                let year = Math.floor(time / 12 / 30 / 24 / 60 / 60 / 1000)
                let day = Math.floor((time / 1000 / 60 / 60 / 24) % 12)
                return `${year}年${day}天`
            },
        },
        mounted() {
            this.getHotArticle()
        },
    }
</script>

<style lang="less" scoped>
    .blog-right-side {
        width: 240px;
        min-height: calc(100vh - 100px);
        background-color: #f9f9f9;
        .side-head {
            display: flex;
            justify-content: space-around;
            align-items: center;
            color: #777;
            box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.05), 0 0 0 rgba(0, 0, 0, 0.05);
            li {
                width: 40px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                &.select {
                    color: #555;
                    border-bottom: 2px solid #000;
                }
                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        .hot-art {
            box-sizing: border-box;
            padding: 20px;
            h5 {
                margin-bottom: 10px;
                font-weight: normal;
                font-size: 16px;
                color: #777;
            }
            ul li {
                display: flex;
                padding: 10px 0px;
                cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
                img {
                    width: 40px;
                    height: 36.13px;
                    border-radius: 10px;
                    margin-right: 10px;
                    box-shadow: 2px 2px 3px #e1e1e1;
                }
                .hot-article-info {
                    font-size: 14px;
                    color: #777;
                    opacity: 0.9;
                    font-family: 'Source Sans Pro';
                    h6 {
                        font-size: 14px;
                        font-family: 'Source Sans Pro';
                    }
                    p {
                        // display: flex;
                        // align-items: center;
                        font-size: 13px;
                        // vertical-align: middle;
                        line-height: 16px;
                        margin-top: 5px;
                        svg {
                            width: 14px;
                            height: 14px;
                            // line-height: 16px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
        .blog-info {
            padding: 20px;
            h5 {
                font-size: 16px;
                font-weight: normal;
                margin-bottom: 10px;
                color: #777;
            }
            ul {
                border-radius: 5px;
                background-color: #fefefe;
                box-shadow: 1px 1px 2px #e1e1e1;
                li {
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    width: 100%;
                    padding: 15px;
                    color: #777;
                    opacity: 0.8;
                    svg {
                        width: 13px;
                        height: 13px;
                        margin-right: 5px;
                    }
                    span {
                        padding: 2px 7px;
                        font-size: 12px;
                        font-family: 'Source Sans Pro', 'Hiragino Sans GB', 'Microsoft Yahei', SimSun, Helvetica, Arial, Sans-serif, monospace;
                        border-radius: 10px;
                        color: #fff;
                        background-color: #cfdadd;
                    }
                }
            }
        }
    }
</style>
