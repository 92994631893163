<template>
    <div class="login-dialog-con">
        <a-form v-if="!vxLoginStatus" id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit">
            <a-form-item>
                <a-input v-decorator="['userName', {rules: [{required: true, message: 'Please input your username!'}]}]" placeholder="Username">
                    <a-icon slot="prefix" type="user" style="color: rgba(0, 0, 0, 0.25)" />
                </a-input>
            </a-form-item>
            <a-form-item>
                <a-input
                    v-decorator="['userPass', {rules: [{required: true, message: 'Please input your Password!'}]}]"
                    type="password"
                    placeholder="Password"
                >
                    <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
                </a-input>
            </a-form-item>
            <a-form-item>
                <a-checkbox
                    v-decorator="[
                        'remember',
                        {
                            valuePropName: 'checked',
                            initialValue: true,
                        },
                    ]"
                >
                    Remember me
                </a-checkbox>
                <a class="login-form-forgot" href=""> Forgot password </a>
                <a-button type="primary" html-type="submit" class="login-form-button"> {{ LoginStatusDesc.button }} </a-button>
                Or
                <a @click="toggleLoginStatus" href="javascript:;"> {{ LoginStatusDesc.wordding }} </a>
            </a-form-item>
        </a-form>
        <div v-else class="login">
            <div class="login-info">
                <img class="avater" :src="vxUserInfo.userIcon" alt="" />
                <div>
                    <p class="user-name">昵称：{{ vxUserInfo.userName }}</p>
                    <p>注册时间：</p>
                    <p>{{ formatTime(vxUserInfo.userDate) }}</p>
                </div>
            </div>
            <a-button type="primary" class="login-form-button" block @click="$router.push({name: 'PersonalCenter'})"> 个人中心</a-button>
            <a-button @click="loginOut" type="primary" class="login-form-button" block> 退出登录</a-button>
        </div>
    </div>
</template>

<script>
    import {formatTime} from '@/utils/dayjs'
    import {mapActions, mapState} from 'vuex';

    export default {
        props: {
            allreadyLogin: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                loginStatus: true,
            };
        },
        computed: {
            ...mapState({
                vxUserInfo: state => state.user.userInfo,
                vxLoginStatus: state => state.user.loginStatus
            }),
            LoginStatusDesc() {
                return this.loginStatus ?
                    {button: 'Log in', wordding: 'register now !'} :
                    {button: 'Register', wordding: 'log in !'}
            }
        },
        methods: {
            ...mapActions({
                vxUserLogin: 'user/userLogin',
                vxLoginOut: 'user/loginOut'
            }),
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields(async (err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                        const data = await this.vxUserLogin({ruleForm: values});
                        console.log(data);
                        if (data.code) {
                            this.$message.success(data.message)
                            // this.$emit('changeLoginDialog');
                            this.form.resetFields()
                        }
                        else {
                            this.$message.error(data.message)
                        }
                    }
                });
            },
            toggleLoginStatus() {
                this.loginStatus = !this.loginStatus
            },
            formatTime(time) {
                return formatTime(time)
            },
            async loginOut() {
                await this.vxLoginOut()
                this.$message.success('退出成功')
            }
        },
        mounted() {
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'normal_login'});
        },
    }
</script>

<style lang="less" scoped>
    /deep/ .login-dialog-con {
        #components-form-demo-normal-login .login-form {
            max-width: 300px;
        }
        #components-form-demo-normal-login .login-form-forgot {
            float: right;
        }
        #components-form-demo-normal-login .login-form-button {
            width: 100%;
        }
        .ant-form-item {
            margin-bottom: 15px;
        }
        .ant-form-item-control {
            line-height: 32px;
        }
        .ant-btn-primary {
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
            background: #448bff linear-gradient(45deg, #448bff, #44e9ff);
        }
        .login-form-button {
            margin: 5px auto;
        }
        .login-form .ant-form-item {
            &:nth-of-type(3) {
                margin-bottom: 0;
            }
        }
        a {
            color: #1890ff;
            &:hover {
                color: #40a9ff;
            }
        }
    }
    .login {
        .login-info {
            display: flex;
            margin-bottom: 10px;
            img {
                width: 80px;
                height: 80px;
                margin-right: 20px;
            }
            .user-name {
                // color: red;
                margin-bottom: 10px;
            }
        }
    }
</style>