import util from '@/utils/util'
const state = {
	rootSideCollapsed: false,  // false  不折叠
	menuSelectIndex: util.sessionStorage.ROOT_MENU_SELECT_INDEX || 0,
}

const mutations = {
	changeRootSide(state, val) {
		state.rootSideCollapsed = val
	},
	changeMenuIndex(state, val) {
		state.menuSelectIndex = val
		util.sessionStorage.ROOT_MENU_SELECT_INDEX = val
	}
}

const actions = {

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}

